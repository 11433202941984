import { OwesReport, OwesReportItem } from "../../../models";

export const getOwesReport = (data: any): OwesReport => {
  return {
    invoices: data.invoices.map(getOwesReportItem),
  };
};

const getOwesReportItem = (data: any): OwesReportItem => {
  return {
    invoiceId: data.invoice_id,
    orderId: data.order_id,
    name: data.name,
    total: data.total,
    type: data.type,
  };
};
