import React from "react";

import { DriversWidget } from "../../Drivers";
import { OrdersWidget } from "../../Orders";
import { ScheduleWidget } from "../../Schedules";
import { DumpYardsWidget } from "../../DumpYards";
import { PermanentRentalsWidget } from "../../PermanentRentals/PermanentRentalsWidget/PermanentRentalsWidget";
import { ReceivedOrdersWidget } from "../../ReceivedOrders";
import { ReportsWidget } from "../components";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getDrivers, getDriverAddresses } from "../../Drivers/driversSlice";
import { getDashboardOrderStats } from "../../Orders/ordersSlice";
import { getPermanentRentalTemplates } from "../../PermanentRentals/permanentRentalsSlice";
import { getDashboardScheduleStats } from "../../Schedules/scheduleSlice";
import { getOrdersDashboard } from "../../ReceivedOrders/receivedOrdersSlice";

import "./AdminDashboard.scss";

export function AdminDashboard() {
  const dispatch = useAppDispatch();

  const getDriversAPI = useAppSelector((state) => state.drivers.getDrivers);

  React.useEffect(() => {
    dispatch(getDrivers());
    dispatch(getDashboardOrderStats());
    dispatch(getDashboardScheduleStats());
    dispatch(getPermanentRentalTemplates());
    dispatch(getOrdersDashboard());
  }, []);

  React.useEffect(() => {
    if (!getDriversAPI.loading && !!getDriversAPI.value) {
      dispatch(getDriverAddresses());
    }
  }, [getDriversAPI.loading, !!getDriversAPI.value]);

  return (
    <div className="admin-dashboard-component">
      <ReceivedOrdersWidget />
      <DriversWidget />
      <OrdersWidget />
      <ScheduleWidget />
      <DumpYardsWidget />
      <PermanentRentalsWidget />
      <ReportsWidget />
    </div>
  );
}
