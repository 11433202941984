import { Entity } from "./Entity";

export interface Invoice extends Entity {
  orderId: string;
  total: number;
  type: InvoiceType;
  status: InvoiceStatus;
}

export enum InvoiceType {
  DUMPSTER_RENTAL = "dumpster_rental",
  WEIGHT_OVERAGE = "weight_overage",
  CUSTOM = "custom",
}

export const invoiceTypeToText: Record<InvoiceType, string> = {
  [InvoiceType.DUMPSTER_RENTAL]: "Dumpster Rental",
  [InvoiceType.WEIGHT_OVERAGE]: "Weight Overage",
  [InvoiceType.CUSTOM]: "Custom",
};

export enum InvoiceStatus {
  CREATED = "created",
  OWES = "owes",
  PAID = "paid",
  CANCELED = "cancelled",
}
