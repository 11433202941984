import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Layout } from "./features/Layout";
import { Home } from "./features/Home";
import { Auth, RequireAuth, RequireAdmin } from "./features/Auth";
import { Dashboard } from "./features/Dashboard";
import { CreateOrder } from "./features/CreateOrder";
import { CancelOrder } from "./features/CancelOrder";
import { Drivers, Schedule } from "./features/Drivers";
import { Orders, Order } from "./features/Orders";
import { Schedules } from "./features/Schedules";
import { DumpYards } from "./features/DumpYards";
import { PermanentRentals } from "./features/PermanentRentals/PermanentRentals/PermanentRentals";
import { ReceivedOrdersView } from "./features/ReceivedOrders";
import { ClientOrder, ClientOrderHistory } from "./features/ClientDashboard";
import { PunchCardsView } from "./features/PunchCards";
import { OwesReportView } from "./features/Reports";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="create-order" element={<CreateOrder />} />
            <Route path="cancel-order" element={<CancelOrder />} />
            <Route path="auth/*" element={<Auth />} />

            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />

            <Route
              path="/drivers/:id"
              element={
                <RequireAdmin>
                  <Schedule />
                </RequireAdmin>
              }
            />

            <Route
              path="/drivers"
              element={
                <RequireAdmin>
                  <Drivers />
                </RequireAdmin>
              }
            />

            <Route path="/orders/:id" element={<Order />} />

            <Route
              path="/orders"
              element={
                <RequireAuth>
                  <Orders />
                </RequireAuth>
              }
            />

            <Route
              path="/received_orders"
              element={
                <RequireAuth>
                  <ReceivedOrdersView />
                </RequireAuth>
              }
            />

            <Route
              path="/schedules"
              element={
                <RequireAdmin>
                  <Schedules />
                </RequireAdmin>
              }
            />

            <Route
              path="/dump_yards"
              element={
                <RequireAdmin>
                  <DumpYards />
                </RequireAdmin>
              }
            />

            <Route
              path="/permanent_rentals"
              element={
                <RequireAdmin>
                  <PermanentRentals />
                </RequireAdmin>
              }
            />

            <Route
              path="/customer_orders/history"
              element={<ClientOrderHistory />}
            />

            <Route path="/customer_orders/:id" element={<ClientOrder />} />

            <Route
              path="/punch_cards"
              element={
                <RequireAdmin>
                  <PunchCardsView />
                </RequireAdmin>
              }
            />

            <Route
              path="/reports/owes"
              element={
                <RequireAdmin>
                  <OwesReportView />
                </RequireAdmin>
              }
            />

            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
