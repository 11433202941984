import React from "react";
import { BsChevronDown, BsChevronUp, BsX } from "react-icons/bs";
import { useDrag } from "react-dnd";

import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  DumpsterSize,
  ScheduleItem,
  ScheduleItemType,
  ServiceType,
} from "../../../models";
import {
  changeScheduleItemOrder,
  unassignScheduleItem,
} from "../../Schedules/scheduleSlice";
import { pickupUnscheduled, changePickupPriority } from "../pickUpsSlice";
import { MaterialDisposingPill } from "../../Shared";
import { setShowOrderDetailsModal } from "../../Orders/ordersSlice";

import "./ScheduledPickupItem.scss";

export interface ScheduledPickupItemProps {
  scheduleItem: ScheduleItem;

  allowMoveUp: boolean;
  allowMoveDown: boolean;
}

export function ScheduledPickupItem(props: ScheduledPickupItemProps) {
  const dispatch = useAppDispatch();

  const { scheduleItem, allowMoveDown, allowMoveUp } = props;

  const [isUnassigning, setIsUnassigning] = React.useState(false);

  const pickup = useAppSelector((state) =>
    state.pickups.pickups.find((p) => p.id === scheduleItem.pickupId)
  );
  const isLoading = useAppSelector(
    (state) => state.schedules.unassignScheduleItem.loading
  );
  const changePickupPriorityAPI = useAppSelector(
    (state) => state.pickups.changePickupPriorityAPI
  );

  React.useEffect(() => {
    return () => {
      if (isUnassigning) {
        dispatch(pickupUnscheduled(scheduleItem.pickupId));
      }
    };
  }, [isUnassigning]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ScheduleItemType.PICK_UP,
    item: {
      id: scheduleItem.id,
      type: ScheduleItemType.PICK_UP,
      scheduleId: scheduleItem.scheduleId,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  if (!pickup) {
    return null;
  }

  const onUnassignScheduleItem = () => {
    if (!isLoading) {
      setIsUnassigning(true);
      dispatch(unassignScheduleItem(scheduleItem.id));
    }
  };

  const onShiftUp = () => {
    if (!isLoading) {
      if (allowMoveUp) {
        const nextOrder = scheduleItem.orderNumber - 1;

        dispatch(
          changeScheduleItemOrder({
            id: scheduleItem.id,
            newOrderNumber: nextOrder,
          })
        );
      }
    }
  };

  const onShiftDown = () => {
    if (!isLoading) {
      if (allowMoveDown) {
        const nextOrder = scheduleItem.orderNumber + 1;

        dispatch(
          changeScheduleItemOrder({
            id: scheduleItem.id,
            newOrderNumber: nextOrder,
          })
        );
      }
    }
  };

  const onPriorityDown = () => {
    const newPriority = pickup.priority - 1;
    if (newPriority < 1) return;
    if (changePickupPriorityAPI.loading) return;

    dispatch(
      changePickupPriority({
        id: pickup.id,
        priority: newPriority,
      })
    );
  };

  const onPriorityUp = () => {
    const newPriority = pickup.priority + 1;
    if (newPriority > 5) return;
    if (changePickupPriorityAPI.loading) return;

    dispatch(
      changePickupPriority({
        id: pickup.id,
        priority: newPriority,
      })
    );
  };

  const dumpsterSize =
    pickup.dumpsterSize === DumpsterSize.SIZE_CLEAN_FILL
      ? "CF"
      : pickup.dumpsterSize;

  const address = `${pickup.address}, ${pickup.zip}`;

  const onDoubleClick = () => {
    dispatch(setShowOrderDetailsModal(pickup.orderId));
  };

  return (
    <div className="scheduled-pickup-item-component">
      <div className="order-container">
        {allowMoveUp && (
          <BsChevronUp className="shift-button" onClick={onShiftUp} />
        )}
        <div className="order-text">{scheduleItem.orderNumber}</div>
        {allowMoveDown && (
          <BsChevronDown className="shift-button" onClick={onShiftDown} />
        )}
      </div>

      <div
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
        }}
        className={`pickup-content-container ${
          pickup.service === ServiceType.JUNK ? "junk" : ""
        }`}
        onDoubleClick={onDoubleClick}
      >
        <div className="title-container">
          <div className="name-container">
            <div className="name-text">{pickup.name}</div>
            <div className="size-text">{dumpsterSize}</div>
            <div className="margin-right">
              <MaterialDisposingPill
                materialDisposing={pickup.materialDisposing}
              />
            </div>
            {pickup.service === ServiceType.JUNK && (
              <div className="size-text">Junk</div>
            )}
          </div>

          <BsX className="x-icon" onClick={onUnassignScheduleItem} />
        </div>

        <div>
          <div className="address-text">{address}</div>
        </div>

        {pickup.instructions.length > 0 && (
          <div className="instructions-text">{pickup.instructions}</div>
        )}

        <div className="priority-container">
          <div className="control-button" onClick={onPriorityDown}>
            -
          </div>
          <div className="label">{`Priority: ${pickup.priority}`}</div>
          <div className="control-button" onClick={onPriorityUp}>
            +
          </div>
        </div>
      </div>
    </div>
  );
}
