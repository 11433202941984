import React from "react";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  getOwesReport,
  resetMarkPaid,
  resetCancelInvoice,
} from "../../reportsSlice";
import { InvoiceItem } from "./InvoiceItem";

import "./OwesReportView.scss";

export const OwesReportView: React.FC = () => {
  const dispatch = useAppDispatch();

  const getOwesReportAPI = useAppSelector(
    (state) => state.reports.getOwesReportAPI
  );
  const owesReport = useAppSelector((state) => state.reports.owesReport);
  const markPaidAPI = useAppSelector((state) => state.reports.markPaidAPI);
  const cancelInvoiceAPI = useAppSelector(
    (state) => state.reports.cancelInvoiceAPI
  );

  React.useEffect(() => {
    dispatch(getOwesReport());
  }, [dispatch]);

  React.useEffect(() => {
    if (!markPaidAPI.loading && !!markPaidAPI.error) {
      toast.error("Something went wrong. Please try again.");
      dispatch(resetMarkPaid());
    }

    if (!markPaidAPI.loading && markPaidAPI.value) {
      toast.success("Successfully marked invoice paid.");
      dispatch(getOwesReport());
    }
  }, [markPaidAPI.error, markPaidAPI.value, markPaidAPI.loading]);

  React.useEffect(() => {
    if (!cancelInvoiceAPI.loading && !!cancelInvoiceAPI.error) {
      toast.error("Something went wrong. Please try again.");
      dispatch(resetCancelInvoice());
    }

    if (!cancelInvoiceAPI.loading && cancelInvoiceAPI.value) {
      toast.success("Successfully cancelled invoice.");
      dispatch(getOwesReport());
    }
  }, [
    cancelInvoiceAPI.error,
    cancelInvoiceAPI.value,
    cancelInvoiceAPI.loading,
  ]);

  return (
    <div className="owes-report-view-component">
      <div className="title-container">
        <div className="title-text">Owes Report</div>
        {owesReport && (
          <div className="title-count">{owesReport.invoices.length}</div>
        )}
        {(getOwesReportAPI.loading || markPaidAPI.loading) && (
          <div style={{ marginLeft: 8 }}>
            <Oval
              color="#131313"
              height={20}
              width={20}
              secondaryColor="#0A60BB"
            />
          </div>
        )}
      </div>

      <div className="invoices-container">
        {owesReport?.invoices.map((i) => (
          <InvoiceItem key={i.invoiceId} invoice={i} />
        ))}
      </div>
    </div>
  );
};
