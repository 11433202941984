import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  APIResult,
  emptyAPIResult,
  loadingAPIResult,
  successAPIResult,
  errorAPIResult,
  ApiError,
  defaultError,
} from "../../lib/redux";
import { MarkPaidParams, triplePSDatasource } from "../../datasource";
import { OwesReport } from "../../models";

// import { markPaid } from '../invoices/invoiceSlice';

export interface ReportsState {
  getOwesReportAPI: APIResult<OwesReport>;
  markPaidAPI: APIResult<boolean>;
  cancelInvoiceAPI: APIResult<boolean>;

  owesReport?: OwesReport;
}

const initialState: ReportsState = {
  getOwesReportAPI: emptyAPIResult(),
  markPaidAPI: emptyAPIResult(),
  cancelInvoiceAPI: emptyAPIResult(),
};

export const getOwesReport = createAsyncThunk<
  OwesReport,
  undefined,
  { rejectValue: ApiError }
>("reports/getOwesReport", async (_, { rejectWithValue }) => {
  try {
    const data = await triplePSDatasource.getOwesReport();

    return data;
  } catch (err: any) {
    const error = err.response.data?.errors?.[0] || defaultError;

    return rejectWithValue(error);
  }
});

export const markPaid = createAsyncThunk<
  boolean,
  MarkPaidParams,
  { rejectValue: ApiError }
>("reports/markPaid", async (params, { rejectWithValue }) => {
  try {
    const data = await triplePSDatasource.markPaid(params);

    return data;
  } catch (err: any) {
    const error = err.response.data?.errors?.[0] || defaultError;

    return rejectWithValue(error);
  }
});

export const cancelInvoice = createAsyncThunk<
  boolean,
  MarkPaidParams,
  { rejectValue: ApiError }
>("reports/cancelInvoice", async (params, { rejectWithValue }) => {
  try {
    const data = await triplePSDatasource.markPaid(params);

    return data;
  } catch (err: any) {
    const error = err.response.data?.errors?.[0] || defaultError;

    return rejectWithValue(error);
  }
});

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    resetMarkPaid: (state) => {
      state.markPaidAPI = emptyAPIResult();
    },
    resetCancelInvoice: (state) => {
      state.cancelInvoiceAPI = emptyAPIResult();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOwesReport.pending, (state) => {
        state.getOwesReportAPI = loadingAPIResult();
      })
      .addCase(getOwesReport.fulfilled, (state, action) => {
        state.getOwesReportAPI = successAPIResult(action.payload);
        state.owesReport = action.payload;
      })
      .addCase(getOwesReport.rejected, (state, action) => {
        state.getOwesReportAPI = errorAPIResult(action.payload!);
      })
      .addCase(markPaid.pending, (state) => {
        state.markPaidAPI = loadingAPIResult();
      })
      .addCase(markPaid.fulfilled, (state, action) => {
        state.markPaidAPI = successAPIResult(action.payload);
      })
      .addCase(markPaid.rejected, (state, action) => {
        state.markPaidAPI = errorAPIResult(action.payload!);
      })
      .addCase(cancelInvoice.pending, (state) => {
        state.cancelInvoiceAPI = loadingAPIResult();
      })
      .addCase(cancelInvoice.fulfilled, (state, action) => {
        state.cancelInvoiceAPI = successAPIResult(action.payload);
      })
      .addCase(cancelInvoice.rejected, (state, action) => {
        state.cancelInvoiceAPI = errorAPIResult(action.payload!);
      });
  },
});

export const { resetMarkPaid, resetCancelInvoice } = reportsSlice.actions;
export default reportsSlice.reducer;
