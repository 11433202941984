import React from "react";
import { BsChevronDown, BsChevronUp, BsX } from "react-icons/bs";
import { useDrag } from "react-dnd";

import { useAppSelector, useAppDispatch } from "../../../app/hooks";
import {
  DumpsterSize,
  ScheduleItem,
  ScheduleItemType,
  ServiceType,
} from "../../../models";
import {
  changeScheduleItemOrder,
  unassignScheduleItem,
} from "../../Schedules/scheduleSlice";
import {
  deliveryUnscheduled,
  changeDeliveryPriority,
} from "../deliveriesSlice";
import { MaterialDisposingPill } from "../../Shared";
import { setShowOrderDetailsModal } from "../../Orders/ordersSlice";

import "./ScheduledDeliveryItem.scss";

export interface ScheduledDeliveryItemProps {
  scheduleItem: ScheduleItem;

  allowMoveUp: boolean;
  allowMoveDown: boolean;
}

export function ScheduledDeliveryItem(props: ScheduledDeliveryItemProps) {
  const dispatch = useAppDispatch();

  const { scheduleItem, allowMoveDown, allowMoveUp } = props;

  const [isUnassigning, setIsUnassigning] = React.useState(false);

  const delivery = useAppSelector((state) =>
    state.deliveries.deliveries.find((d) => d.id === scheduleItem.deliveryId)
  );
  const isLoading = useAppSelector(
    (state) => state.schedules.unassignScheduleItem.loading
  );
  const changeDeliveryPriorityAPI = useAppSelector(
    (state) => state.deliveries.changeDeliveryPriorityAPI
  );

  React.useEffect(() => {
    return () => {
      if (isUnassigning) {
        dispatch(deliveryUnscheduled(scheduleItem.deliveryId));
      }
    };
  }, [isUnassigning]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ScheduleItemType.DELIVERY,
    item: {
      id: scheduleItem.id,
      type: ScheduleItemType.DELIVERY,
      scheduleId: scheduleItem.scheduleId,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  if (!delivery) {
    return null;
  }

  const onUnassignScheduleItem = () => {
    if (!isLoading) {
      setIsUnassigning(true);
      dispatch(unassignScheduleItem(scheduleItem.id));
    }
  };

  const onShiftUp = () => {
    if (!isLoading) {
      if (allowMoveUp) {
        const nextOrder = scheduleItem.orderNumber - 1;

        dispatch(
          changeScheduleItemOrder({
            id: scheduleItem.id,
            newOrderNumber: nextOrder,
          })
        );
      }
    }
  };

  const onShiftDown = () => {
    if (!isLoading) {
      if (allowMoveDown) {
        const nextOrder = scheduleItem.orderNumber + 1;

        dispatch(
          changeScheduleItemOrder({
            id: scheduleItem.id,
            newOrderNumber: nextOrder,
          })
        );
      }
    }
  };

  const onPriorityDown = () => {
    const newPriority = delivery.priority - 1;
    if (newPriority < 1) return;
    if (changeDeliveryPriorityAPI.loading) return;

    dispatch(
      changeDeliveryPriority({
        id: delivery.id,
        priority: newPriority,
      })
    );
  };

  const onPriorityUp = () => {
    const newPriority = delivery.priority + 1;
    if (newPriority > 5) return;
    if (changeDeliveryPriorityAPI.loading) return;

    dispatch(
      changeDeliveryPriority({
        id: delivery.id,
        priority: newPriority,
      })
    );
  };

  const dumpsterSize =
    delivery.dumpsterSize === DumpsterSize.SIZE_CLEAN_FILL
      ? "CF"
      : delivery.dumpsterSize;

  const address = `${delivery.address}, ${delivery.zip}`;

  const onDoubleClick = () => {
    dispatch(setShowOrderDetailsModal(delivery.orderId));
  };

  return (
    <div className="scheduled-delivery-item-component">
      <div className="order-container">
        {allowMoveUp && (
          <BsChevronUp className="shift-button" onClick={onShiftUp} />
        )}
        <div className="order-text">{scheduleItem.orderNumber}</div>
        {allowMoveDown && (
          <BsChevronDown className="shift-button" onClick={onShiftDown} />
        )}
      </div>

      <div
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
        }}
        className={`delivery-content-container ${
          delivery.service === ServiceType.JUNK ? "junk" : ""
        }`}
        onDoubleClick={onDoubleClick}
      >
        <div className="title-container">
          <div className="name-container">
            <div className="name-text">{delivery.name}</div>
            <div className="size-text">{dumpsterSize}</div>
            <div className="margin-right">
              <MaterialDisposingPill
                materialDisposing={delivery.materialDisposing}
              />
            </div>
            {delivery.service === ServiceType.JUNK && (
              <div className="size-text">Junk</div>
            )}
          </div>

          <BsX className="x-icon" onClick={onUnassignScheduleItem} />
        </div>

        <div>
          <div className="address-text">{address}</div>
        </div>

        {delivery.instructions.length > 0 && (
          <div className="instructions-text">{delivery.instructions}</div>
        )}

        <div className="priority-container">
          <div className="control-button" onClick={onPriorityDown}>
            -
          </div>
          <div className="label">{`Priority: ${delivery.priority}`}</div>
          <div className="control-button" onClick={onPriorityUp}>
            +
          </div>
        </div>
      </div>
    </div>
  );
}
