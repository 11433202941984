import React from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  InvoiceStatus,
  invoiceTypeToText,
  OwesReportItem,
  PaymentType,
} from "../../../../models";
import { markPaid, cancelInvoice } from "../../reportsSlice";

export interface InvoiceItemProps {
  invoice: OwesReportItem;
}

export const InvoiceItem: React.FC<InvoiceItemProps> = ({ invoice }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const markPaidAPI = useAppSelector((state) => state.reports.markPaidAPI);

  const [selectedPaymentType, setSelectedPaymentType] = React.useState(
    PaymentType.CARD
  );

  const onViewOrdeClick = () => navigate(`/orders/${invoice.orderId}`);

  const onMarkPaid = () => {
    if (markPaidAPI.loading) return;

    dispatch(
      markPaid({
        id: invoice.invoiceId,
        paymentType: selectedPaymentType,
        status: InvoiceStatus.PAID,
      })
    );
  };

  const onCancelInvoice = () => {
    if (markPaidAPI.loading) return;

    dispatch(
      cancelInvoice({
        id: invoice.invoiceId,
        paymentType: selectedPaymentType,
        status: InvoiceStatus.CANCELED,
      })
    );
  };

  const handlePaymentTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedPaymentType(event.target.value as PaymentType);
  };

  return (
    <div className="invoice-container" key={invoice.invoiceId}>
      <div className="info-container">
        <div className="info-item">
          <div className="info-label">Name:</div>
          <div className="info-value">{invoice.name}</div>
        </div>

        <div className="info-item">
          <div className="info-label">Type:</div>
          <div className="info-value">{invoiceTypeToText[invoice.type]}</div>
        </div>

        <div className="info-item">
          <div className="info-label">Total:</div>
          <div className="info-value">{`$${invoice.total}`}</div>
        </div>

        <div className="view-order-button" onClick={onViewOrdeClick}>
          View order
        </div>
      </div>

      <div className="vertical-divider" />

      <div className="actions-container">
        <select
          value={selectedPaymentType}
          onChange={handlePaymentTypeChange}
          className="payment-type-select"
        >
          {Object.values(PaymentType).map((type) => (
            <option key={type} value={type}>
              {type === PaymentType.PAYPAL
                ? "Cash App"
                : type.charAt(0).toUpperCase() + type.slice(1)}
            </option>
          ))}
        </select>

        <div className="button" onClick={onMarkPaid}>
          Mark paid
        </div>

        <div className="button" onClick={onCancelInvoice}>
          Cancel
        </div>
      </div>
    </div>
  );
};
