import React from "react";
import { useDrag } from "react-dnd";

import { useAppDispatch } from "../../../app/hooks";
import {
  Delivery,
  DumpsterSize,
  ScheduleItemType,
  ServiceType,
} from "../../../models";
import { MaterialDisposingPill } from "../../Shared";
import { setShowOrderDetailsModal } from "../../Orders/ordersSlice";

import "./DeliveryItem.scss";

export interface DeliveryItemProps {
  delivery: Delivery;
}

export function DeliveryItem(props: DeliveryItemProps) {
  const dispatch = useAppDispatch();

  const { delivery } = props;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ScheduleItemType.DELIVERY,
    item: { id: delivery.id, type: ScheduleItemType.DELIVERY },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const dumpsterSize =
    delivery.dumpsterSize === DumpsterSize.SIZE_CLEAN_FILL
      ? "CF"
      : delivery.dumpsterSize;

  const address = `${delivery.address}, ${delivery.zip}`;

  const onDoubleClick = () => {
    dispatch(setShowOrderDetailsModal(delivery.orderId));
  };

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
      className={`delivery-item-component ${
        delivery.service === ServiceType.JUNK ? "junk" : ""
      }`}
      onDoubleClick={onDoubleClick}
    >
      <div className="name-container">
        <div className="name-text">{delivery.name}</div>
        <div className="size-text">{dumpsterSize}</div>
        <div className="margin-right">
          <MaterialDisposingPill
            materialDisposing={delivery.materialDisposing}
          />
        </div>
        {delivery.service === ServiceType.JUNK && (
          <div className="size-text">Junk</div>
        )}
      </div>

      <div>
        <div className="address-text">{address}</div>
      </div>

      {delivery.instructions.length > 0 && (
        <div className="instructions-text">{delivery.instructions}</div>
      )}
    </div>
  );
}
