import React from "react";
import { useDrag } from "react-dnd";

import { useAppDispatch } from "../../../app/hooks";
import {
  DumpsterSize,
  PickUp,
  ScheduleItemType,
  ServiceType,
} from "../../../models";
import { MaterialDisposingPill } from "../../Shared";
import { setShowOrderDetailsModal } from "../../Orders/ordersSlice";

import "./PickupItem.scss";

export interface PickupItemProps {
  pickup: PickUp;
}

export function PickupItem(props: PickupItemProps) {
  const dispatch = useAppDispatch();

  const { pickup } = props;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ScheduleItemType.PICK_UP,
    item: { id: pickup.id, type: ScheduleItemType.PICK_UP },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const dumpsterSize =
    pickup.dumpsterSize === DumpsterSize.SIZE_CLEAN_FILL
      ? "CF"
      : pickup.dumpsterSize;

  const address = `${pickup.address}, ${pickup.zip}`;

  const onDoubleClick = () => {
    dispatch(setShowOrderDetailsModal(pickup.orderId));
  };

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
      className={`pickup-item-component ${
        pickup.service === ServiceType.JUNK ? "junk" : ""
      }`}
      onDoubleClick={onDoubleClick}
    >
      <div className="name-container">
        <div className="name-text">{pickup.name}</div>
        <div className="size-text">{dumpsterSize}</div>
        <div className="margin-right">
          <MaterialDisposingPill materialDisposing={pickup.materialDisposing} />
        </div>
        {pickup.service === ServiceType.JUNK && (
          <div className="size-text">Junk</div>
        )}
      </div>

      <div>
        <div className="address-text">{address}</div>
      </div>

      {pickup.instructions.length > 0 && (
        <div className="instructions-text">{pickup.instructions}</div>
      )}
    </div>
  );
}
