import React from "react";
import { useNavigate } from "react-router-dom";

import "./ReportsWidget.scss";

export const ReportsWidget: React.FC = () => {
  const navigate = useNavigate();

  const handleOwesReportClick = () => navigate("/reports/owes");

  return (
    <div className="reports-widget-component">
      <div className="title">Reports</div>

      <div className="link" onClick={handleOwesReportClick}>
        Owes report
      </div>
    </div>
  );
};
